export default {
    state : ()=>{
        return {
            userinfo:{
                token:"123",
                username:localStorage.getItem("emil")
            },
            student_list:localStorage.getItem("student_list") ,
            token:localStorage.getItem("token"),
            current_student:localStorage.getItem("current_student")
        }
    },
    mutations:{
        updateName(state,name){
            state.userinfo.username = name;
        },
        SET_TOKEN:(state,token)=>{
            state.token = token;
        },
        SET_STUDENT_LIST(state,list){
            state.student_list = list;
        },
        SET_COURRENT_STUDENT(state,student){
            state.current_student = student
        }
    },
    actions:{
        updateNameAsync({commit},name){
            setTimeout(()=>{
                commit('updateName',name);
            },1000)
        },
        clearToken(context){
            context.commit("SET_TOKEN","");
        }
    }
}