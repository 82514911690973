import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/home/'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/homeview/:id',
    name: 'homeview',
    component: () => import('@/views/home/index')
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('@/views/features/index'),
    props: true
  },
  {
    path: '/classindex',
    name: 'classindex',
    component: () => import('@/views/features/classindex'),
    props: true
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/billing/index')
  },
  {
    path: '/cashier',
    name: 'cashier',
    component: () => import('@/views/cashier/index')
  },
  {
    path: '/stripepay',
    name: 'stripepay',
    component: () => import('@/views/stripepay/index')
  },
  {
    path: '/wechatpay',
    name: 'wechatpay',
    component: () => import('@/views/wechatpay/index')
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/coupon/index')
  },
  {
    path: '/clockin',
    name: 'clockin',
    component: () => import('@/views/clockin/index')
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: () => import('@/views/shoppingMall/index')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/shoppingMall/Cart')
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/shoppingMall/address')
  },
  {
    path: '/pay_address',
    name: 'pay_address',
    component: () => import('@/views/billing/address')
  },
  {
    path: '/records',
    name: 'records',
    component: () => import('@/views/shoppingMall/records')
  },
  {
    path: '/audition',
    name: 'audition',
    component: () => import('@/views/audition/index')
  },
  {
    path: '/student',
    name: 'student',
    component: () => import('@/views/student/index')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
