import "core-js/modules/es.array.push.js";
import { registerApi, loginApiTwo } from "@/api/getData";
import { useRouter } from "vue-router";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import { reactive, toRefs, onMounted, ref } from "vue";
export default {
  name: "PhonePayLogin",
  setup() {
    const obj = reactive({
      emil: null,
      password: null,
      passwordTwo: null,
      student_list: null,
      studentIndex: null,
      studentNum: 0,
      state: 404,
      loading: false,
      jishi: 0,
      isLogin: true
    });
    const router = useRouter();
    const pad = (num, n) => {
      let sz = num + "";
      for (let i = 0; i < n; i++) {
        if (i >= Array.from(sz).length) {
          sz = "0" + sz;
        }
      }
      return sz;
    };
    let url = window.location.href;
    if (url.indexOf("type=") != -1) {
      let zjtz = url.split("type=")[1];
      zjtz = zjtz.replaceAll("type=", "").replaceAll("#/", "");
      obj.state = zjtz;
    }
    const selectChange = () => {
      localStorage.setItem("current_student", JSON.stringify(obj.student_list[obj.studentIndex]));
    };
    let lang = localStorage.getItem("language");
    const getUserDetail = () => {
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.com$/;
      const data1 = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.cn$/;
      if (!data.test(obj.emil) && !data1.test(obj.emil)) {
        // ElMessage.error(
        //   lang == "en" ? "Please enter the correct email address!" : "请输入正确的邮箱!"
        // );
        return false;
      }
    };
    onMounted(() => {
      let url = window.location.href;
      if (url.indexOf("type=") != -1) {
        let zjtz = url.split("type=")[1];
        zjtz = zjtz.replaceAll("type=", "").replaceAll("#/", "");
        obj.state = zjtz;
      }
      localStorage.setItem("current_student", null);
      localStorage.setItem("emil", null);
      localStorage.setItem("user_vo", null);
      localStorage.setItem("current_student", null);
      localStorage.setItem("student_list", null);
      localStorage.setItem("token", null);
    });
    const submit = () => {
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.com$/;
      const data1 = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.cn$/;
      if (!data.test(obj.emil) && !data1.test(obj.emil)) {
        ElMessage.error(lang == "en" ? "Please enter the correct email address!" : "请输入正确的邮箱!");
        return false;
      }
      if (obj.password == null) {
        ElMessage.error(lang == "en" ? "Password cannot be empty!" : "密码不能为空!");
        return false;
      }
      localStorage.setItem("password", obj.password);
      loginApiTwo(obj.emil, obj.password).then(res => {
        if (res.data.code == 0) {
          let data = res.data.data;
          obj.student_list = data.student_list;
          obj.studentNum = obj.student_list.length;
          if (obj.studentNum <= 0) {
            return false;
          }
          localStorage.setItem("emil", obj.emil);
          localStorage.setItem("user_vo", JSON.stringify(data.user_vo));
          localStorage.setItem("current_student", JSON.stringify(data.student_list[0]));
          localStorage.setItem("student_list", JSON.stringify(data.student_list));
          localStorage.setItem("token", data.token);
          localStorage.setItem("myHeadIndex", 0);
          ElMessage.success(lang == "en" ? "register was successful!" : "登录成功!");
          obj.loading = true;
          router.push("features");
        } else {
          ElMessage.error(lang == "en" ? "Account or password error!" : "账号或密码错误!");
        }
      });
    };
    const goToMain = () => {
      if (obj.emil == null || obj.password == null || obj.passwordTwo == null || obj.emil == "" || obj.password == "" || obj.passwordTwo == "") {
        ElMessage.error(lang == "en" ? "Content cannot be empty!" : "内容不能为空!");
        return false;
      }
      localStorage.setItem("password", obj.password);
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
      if (!data.test(obj.emil)) {
        ElMessage.error(lang == "en" ? "Please enter the correct email address!" : "请输入正确的邮箱!");
        return false;
      }
      if (obj.passwordTwo != obj.password) {
        ElMessage.error(lang == "en" ? "The two passwords entered do not match!" : "两次输入密码不一致!");
        return false;
      }
      obj.loading = true;
      registerApi(obj.emil, obj.passwordTwo).then(res => {
        obj.loading = false;
        if (res.data.code == 0) {
          ElMessage.success(lang == "en" ? "register was successful!" : "注册成功!");
          obj.isLogin = true;
        } else {
          // ElMessage.alert(
          //   lang == "en"
          //     ? "Registration failed, account already exists!"
          //     : "注册失败,账号已存在!"
          // );
          ElMessage.error(lang == "en" ? "Registration failed, account already exists!" : "注册失败,账号已存在!");
        }
      });
    };
    return {
      submit,
      ...toRefs(obj),
      getUserDetail,
      selectChange,
      goToMain
    };
  }
};