import axios from "axios";
import router from "./router";
import { ElMessageBox } from 'element-plus'

const BASE_URL = ''
const service = axios.create({
    baseURL: "https://victor.wxc1997cheshi.top",
    // baseURL: "http://127.0.0.1:443",
    // baseURL:"https://stripe.wxc1997cheshi.top",
    timeout: 10000
})

// http request 请求拦截器 发送请求前拦截
service.interceptors.request.use(
    config => {
      // 这里判断localStorage里面是否存在token，如果有则在请求头里面设置
      if (localStorage.token) {
        config.headers.token = localStorage.getItem("token");
      }
      return config
    },
    err => {
      return Promise.reject(err)
    }
  )
  
// http response 接收拦截器 接收数据前拦截
service.interceptors.response.use(response => {
    
    const res = response.data;
    let lang = localStorage.getItem("language");
    //token 过期
    if (res.msg == "账号未登录") {
        localStorage.removeItem("token");
        ElMessageBox.alert(lang == 'en' ? 'Login expired, please log in again!' : '登录过期,请重新登录！', 'Title', {
            confirmButtonText: 'OK',
            callback: () => {
                router.push({
                    path: "/"
                })
            },
        })
    }
    //  else if (res.code == -1) {
    //     ElMessageBox.alert(lang == 'en' ? 'Network abnormality, please refresh this webpage!' : '网络异常，请刷新本网页！', 'Title', {
    //         confirmButtonText: 'OK',
    //     })
    //     return Promise.reject(new Error(res.msg || 'Error'))
    // }
    return response;
},error=>{
    console.log('err' + error) // for debug
    // return Promise.reject(error)
});

export default service;
