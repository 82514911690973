


export default {
    state : ()=>{
        return {
            lang:'zh',
            country:null
        }
    },
    mutations:{
        updateLang(state,name){
            state.lang = name;
        },
        updateCountry(state,name){
            state.country = name;
        }
    }
}