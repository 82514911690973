import axios from "../wbrequest.js";
const Authorization = "APPCODE ca81dc1b1b1242139e16f0cb37f1b3cc"

// 汇率转换 把 fromCode 币种代码（如USD） 转换为 toCode 币种代码（如CNY) ，中间money 是 fromCode 币种的金额
export const getHuilv = (fromCode, money, toCode) => axios({
    method: "get",
    headers: { Authorization },
    url: `/waihui-transform?fromCode=${fromCode}&money=${money}&toCode=${toCode}`,
})

// 汇率列表 
export const getCodeHuilv = (Code) => axios({
    method: "get",
    headers: { Authorization },
    url: `waihui-list?code=${Code}`,
})


