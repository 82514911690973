import { ref, reactive, inject, toRefs, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "ClickLang",
  setup() {
    const reload = inject("reload");
    const {
      locale
    } = useI18n();
    const obj = reactive({
      lang: 1
    });
    const dropdown1 = ref();
    const showClick = () => {
      if (!dropdown1.value) return;
      {
        dropdown1.value.handleOpen();
      }
    };
    const langaugeClick = num => {
      obj.lang = num;
      switch (num) {
        case 1:
          localStorage.setItem("language", "zh");
          locale.value = "zh";
          break;
        case 2:
          localStorage.setItem("language", "en");
          locale.value = "en";
          break;
      }
      reload();
    };
    onMounted(() => {
      if (localStorage.getItem("language") == null || localStorage.getItem("language") == "" || localStorage.getItem("language") == "null") {
        obj.lang = navigator.language == "zh-CN" ? 1 : 2;
        locale.value = obj.lang == 1 ? "zh" : "en";
      } else {
        obj.lang = localStorage.getItem("language") == "en" ? 2 : 1;
        locale.value = obj.lang == 1 ? "zh" : "en";
        // localStorage.removeItem("language");
      }
    });

    return {
      dropdown1,
      showClick,
      ...toRefs(obj),
      langaugeClick
    };
  }
};