import "core-js/modules/es.array.push.js";
import { loginApi } from "@/api/getData";
import { useRouter } from "vue-router";
import { ElMessage, ElLoading } from "element-plus";
import { reactive, toRefs, onMounted, ref } from "vue";
export default {
  name: "PhonePayLogin",
  setup() {
    const obj = reactive({
      emil: null,
      student_list: null,
      studentIndex: null,
      studentNum: 0,
      state: 404,
      loading: false,
      jishi: 0
    });
    const router = useRouter();
    const pad = (num, n) => {
      let sz = num + "";
      for (let i = 0; i < n; i++) {
        if (i >= Array.from(sz).length) {
          sz = "0" + sz;
        }
      }
      return sz;
    };
    let url = window.location.href;
    if (url.indexOf("type=") != -1) {
      let zjtz = url.split("type=")[1];
      zjtz = zjtz.replaceAll("type=", "").replaceAll("#/", "");
      obj.state = zjtz;
    }
    const selectChange = () => {
      localStorage.setItem("current_student", JSON.stringify(obj.student_list[obj.studentIndex]));
    };
    let lang = localStorage.getItem("language");
    const getUserDetail = () => {
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.com$/;
      const data1 = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.cn$/;
      if (!data.test(obj.emil) && !data1.test(obj.emil)) {
        // ElMessage.error(
        //   lang == "en" ? "Please enter the correct email address!" : "请输入正确的邮箱!"
        // );
        return false;
      }
      const subloading = ElLoading.service({
        lock: true,
        text: lang == "en" ? "Retrieve student accounts..." : "检索学生账号中......",
        background: "rgba(0, 0, 0, 0.7)"
      });
      loginApi(obj.emil, "pp", "[asdfghjkl;'").then(res => {
        subloading.close();
        if (res.data.code == 0) {
          let data = res.data.data;
          obj.student_list = data.student_list;
          obj.studentNum = obj.student_list.length;
          if (obj.studentNum <= 0) {
            return false;
          }
          localStorage.setItem("emil", obj.emil);
          localStorage.setItem("user_vo", JSON.stringify(data.user_vo));
          localStorage.setItem("student_list", JSON.stringify(data.student_list));
          localStorage.setItem("token", data.token);
          localStorage.setItem("current_student", null);
          ElMessage.success(lang == "en" ? "Please select a student account!" : "请选择学生账号!");
          localStorage.setItem("myHeadIndex", 0);
        } else {
          ElMessage.error(lang == "en" ? "Account or password error!" : "账号或密码错误!");
        }
      });
    };
    onMounted(() => {
      let url = window.location.href;
      if (url.indexOf("type=") != -1) {
        let zjtz = url.split("type=")[1];
        zjtz = zjtz.replaceAll("type=", "").replaceAll("#/", "");
        obj.state = zjtz;
      }
    });
    const submit = () => {
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.com$/;
      const data1 = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.cn$/;
      if (!data.test(obj.emil) && !data1.test(obj.emil)) {
        ElMessage.error(lang == "en" ? "Please enter the correct email address!" : "请输入正确的邮箱!");
        return false;
      }
      if (obj.studentIndex == null) {
        ElMessage.error(lang == "en" ? "Please select a student account!" : "请选择学生账号!");
        return false;
      }
      obj.loading = true;
      setTimeout(() => {
        obj.jishi = 1;
      }, 8000);
      switch (obj.state) {
        case "1":
          router.push("billing");
          break;
        case "2":
          router.push("coupon");
          break;
        case "3":
          let yhUrl = "https://share.zhixingchinese.com/";
          let lang = localStorage.getItem("language");
          let student_list = localStorage.getItem("student_list");
          if (student_list != null && student_list != "null" && student_list != "[]") {
            let current_student = JSON.parse(localStorage.getItem("current_student"));
            window.location.href = yhUrl + "?fenxiangid=81" + pad(current_student.id, 4) + "&lang=" + lang;
          } else {
            window.location.href = yhUrl + "?data=404" + "&lang=" + lang;
          }
          break;
        case "4":
          router.push("clockin");
          break;
        case "5":
          router.push("shopping");
          break;
        default:
          router.push("features");
          break;
      }
    };
    return {
      submit,
      ...toRefs(obj),
      getUserDetail,
      selectChange
    };
  }
};