import axios from "../request";

/**
 * 用户相关接口
 * @param {*} emil 
 * @param {*} password 
 * @returns 
 */
//注册接口
export const registerApi = (emil,password) => axios.post("/student-server/api/v1/user/student/register",{
    emil,password
})

//登录接口
export const loginApi = (emil,password,type) => axios.post("/student-server/api/v1/user/student/login",{
    emil,password,type
})
export const loginApiTwo = (emil,password) => axios.post("/student-server/api/v1/user/student/login",{
    emil,password
})
//添加子账号
export const addStudent = (data) => axios({
    method:"post",
    url:"/student-server/api/v1/user/student/add_student",
    data:data
})
//主账号修改
export const updateUser = (phone,wechat,country,whats_app) => axios.post("/student-server/api/v1/user/student/update",{
    phone,wechat,country,whats_app
})
export const updateUserOne = (data) => axios({
    method:"post",
    url:"/student-server/api/v1/user/student/update",
    data:data
})
export const updatePassword = (old_password,password) => axios.post("/student-server/api/v1/user/student/update",{
    old_password,password
})

//获取子账号列表
export const getStudentList = () => axios.get("/student-server/api/v1/user/student/student_list");
//获取账号详细
export const getUserDetail = (student_id) => axios.post("/student-server/api/v1/user/student/student_detail/"+student_id);

//用户发送反馈和建议
export const addMessage = (data) => axios({
    method:"post",
    url:"/student-server/api/v1/message/add_message",
    data:data
})
//获取消息列表
export const getMessage = () => axios.get("/student-server/api/v1/message/get_message");


/**
 * stripe 支付
 */
export const stripePay = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/stripe_pay",
    data:data
})


/**
 * 根据网页信息生成 微信jsapi数据
 */
export const getWeChtUrl = (nict) => axios({
    method:"get",
    url:"/order-server/api/v1/order/get_we_cht_url?nict="+nict,
})




/**
 * 课程商品相关接口
 * @returns 
 */

//获取课程商品列表
export const getRecharge = () => axios.get("/recharge-server/api/v1/course_type/course_list");
//查询书籍详细
export const getBookDetail = (title) => axios.get("/recharge-server/api/v1/book/get_book_detail?title="+title);

//获取优惠券列表
export const getCouponList = (is_state,student_id) => axios.get("/recharge-server/api/v1/coupon/coupon_list/"+is_state+"/"+student_id);

//添加优惠券
export const addCoupon = (orderNo,couponCode) => axios.post("/recharge-server/api/v1/coupon/add_coupon/"+orderNo+"/"+couponCode);

// 修改优惠券状态
export const updateCoupon1 = (order_no,is_state,order_number) => axios.post("/recharge-server/api/v1/coupon/update_coupon/"+order_no+"/"+is_state+"/"+order_number);
export const updateCoupon = (data) => axios({
    method:"post",
    url:"/recharge-server/api/v1/coupon/update_coupon",
    data:data
})

//获取邀请列表
export const getSendingCouponList = (sending_user_id) => axios.get("/recharge-server/api/v1/coupon/sending_coupon_list/"+sending_user_id);



/**
 * 商品订单相关
 */

// 创建订单
export const addOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/confirm_order",
    data:data
})
export const updateOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/update_order",
    data:data
})
// 获取微信支付 二维码
export const getWeChatCodeUrl = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/we_chat_pay",
    data:data
})
// 获取空中云汇创建的支付信息
export const createAirwallexOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/create_airwallex_order",
    data:data
})
// 查询空中云汇是否支付成功，并修改订单状态
export const airwallextPayCallback = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/airwallext_pay_callback",
    data:data
});


// 获取LKL拉卡拉创建的支付信息
export const createLKLOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/create_lkl_order",
    data:data
})
// 获取UseePay拉卡拉创建的支付信息
export const createUseePayOrder = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/order/create_usee_pay_order",
    data:data
})

// 新增/修改分次付款记录
export const addPaymentRecords = (data) => axios({
    method:"post",
    url:"/order-server/api/v1/payment_records/add_payment_records",
    data:data
});
// 分次付款记录列表
export const getPaymentRecords = (user_id) => axios.get("/order-server/api/v1/payment_records/get_payment_records_list/"+user_id);

// 根据订单号获取订单状态
export const getWeCahtOrderPayState = (out_trade_no) => axios.get("/order-server/api/v1/order/we_chat_query_pay_success/"+out_trade_no);


// 订单列表
export const getOrderList = (student_id) => axios.get("/order-server/api/v1/order/order_list/"+student_id);

// 订单详细
export const getOrderDetail = (order_id) => axios.get("/order-server/api/v1/order/order_detail/"+order_id);

// 用户购买、赠送、打卡课程数列表
export const getOCLogsList = (student_id) => axios.get("/order-server/api/v1/obtain_course_logs/get_oc_logs_list/"+student_id);

/**
 * 用户打卡相关
 */

// 用户打卡记录列表
export const getClockInList = (studentId) => axios.get("/student-server/api/v1/user_clock_in/get_clock_in_list/"+studentId);



// 新增用户打卡记录
export const addClockInRecord = (data) => axios({
    method:"post",
    url:"/student-server/api/v1/user_clock_in/add_clock_in_record",
    data
})

// 商品分类列表
export const getCommodityTypeList = () => axios.get("/student-server/api/v1/commodity_type/get_commodity_type_list");

// 商品列表
export const getCommodityList = (typeId) => axios.get("/student-server/api/v1/commodity/get_commodity_list/"+typeId);

// 新增商品兑换记录
export const addExchangeRecords =  (data) => axios({
    method:"post",
    url:"/student-server/api/v1/exchange_records/add_exchange_records",
    data
})

// 用户兑换记录表
export const getExchangeRecordsList = (userId) => axios.get("/student-server/api/v1/exchange_records/get_exchange_records_list/"+userId);




// 测试
export const get_user = () => axios.get("/order-server/api/v1/order/get_user");