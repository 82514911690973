


export default {
    state : ()=>{
        return {
            cartList:localStorage.getItem("cartList")?JSON.parse(localStorage.getItem("cartList")):[],
            minJi:0
        }
    },
    mutations:{
        UPDATECART(state,cartList){
            state.cartList = cartList;
        },
        UPDMINJI(state,minJi){
            state.minJi = minJi;
        }
    }
}