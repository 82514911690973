import ClickLang from "./components/ClickLang.vue";
import LoginTitle from "./components/LoginTitle.vue";
import LoginInput from "./components/LoginInput.vue";
import LoginOrzc from "./components/LoginOrzc.vue";
import { reactive, toRefs, onMounted, ref } from "vue";
import { getHuilv } from "@/api/getWbData";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "PhonePayIndex",
  components: {
    ClickLang,
    LoginInput,
    LoginTitle,
    LoginOrzc
  },
  setup() {
    const obj = reactive({
      typeStr: 404,
      type: 0
    });
    const router = useRouter();
    const route = useRoute();
    if (route.query.isPhone != undefined) {
      obj.type = 1;
    }
    const huilvList = async () => {
      try {
        let sgd = await getHuilv("USD", "1", "SGD");
        localStorage.setItem("U_S", sgd.data.showapi_res_body.rate);
        let cny = await getHuilv("USD", "1", "CNY");
        localStorage.setItem("U_C", cny.data.showapi_res_body.rate);
        let eur = await getHuilv("USD", "1", "eur");
        localStorage.setItem("U_E", eur.data.showapi_res_body.rate);
        let sge = await getHuilv("SGD", "1", "eur");
        localStorage.setItem("S_E", sge.data.showapi_res_body.rate);
      } catch (error) {
        console.log("error:", error);
      }
    };
    onMounted(() => {
      huilvList();
    });
    return {
      ...toRefs(obj)
    };
  }
};