import { createI18n } from 'vue-i18n';
const locale = "zh";

const i18n = createI18n({
    legacy: false, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale:  localStorage.getItem("language")!=null?localStorage.getItem("language"):navigator.language == "zh-CN"?'zh':'en',
    messages: {
        'zh': {
            lang:"zh",
            home: {
                title: '欢迎您！',
                text1: '即使您身处全球，',
                text2: '都能获取一堂优质的中文课程！',
                login:"账号登录",
                log:"登录",
                reg:"注册",
                emil:"请输入邮箱地址",
                password:"请输入密码",
                passwordTwo:"请再次输入密码",
                student:" 请选择学生账号 ",
                submit:"登录",
                tips:"若无可用账号请联系管理员协助注册",
                tipsTwo:"若忘记密码请联系管理员协助处理",
            },
            user:{
                title:"个人中心",
                title1:"管理账号",
                text1:"邮箱",
                text2:"手机号码",
                text3:"微信",
                text4:"所在国家",
                password:"密码",
                save:"保存",
                save1:"编辑",
                user1:"姓名",
                user2:"性别",
                user3:"男",
                user4:"女",
                user5:"生日",
                user6:"在校年级",
                user7:"大概识字量",
                user8:"大概写字量",
                user9:"母语",
                user10:"是否学过拼音",
                user11:"家庭中文环境",
                user12:"中文学习经历",
                user13:"家长期望和备注",
                user14:"详细",
                user15:"收起",
            },
            feat:{
                title:"学员中心",
                viptitle:"会员中心",
                text1:"支付",
                text2:"分享",
                text3:"优惠券",
                text31:"订单",
                text32:"订单",
                text4:"打卡",
                text5:"商城",
                text6:"学员",
                button:"确认",
                total:"课时总数：",
                class:"课时统计",
                account:"待创建"
            },
            bill:{
                title:"账单信息",
                curr:"支付币种",
                currXZ:"选择币种",
                stud:"学生",
                cour:"课程名称",
                courXZ:"选择课程",
                sess:"购买课时",
                sessXZ:"课时包",
                comp:"赠送课时",

                orig:"课程总价",
                unit:"课程单价",
                coup:"优惠券",
                coupXZ:"尚未选择优惠券",
                actu:"优惠总价",
                zsess:"总课时数",
                zactu:"实付总价",
                submit:"提交订单",
                nouse:"不使用",
            },
            cash:{
                title:"收银台",
                text1:"剩余时间：",
                text2:" 超时未支付订单将取消",
                stud:"学生",
                order:"订单编号",
                zsess:"课时总数",
                sfje:"实付金额",
                zfje:"支付金额",
                zfty:"支付方式",
                Partial:"分次付款 ",
                Partial2:"待付金额：",
                Partial3:"点击使用",
                submit:"立即支付",
                pay1:"第一笔",
                pay2:"第二笔",
                pay3:"第三笔",
                pay4:"第四笔",
            },
            qrfk:"确认付款",
            coupon:{
                title:"优惠券",
                type1:"可使用",
                type2:"商城兑换",
                type3:"历史订单",
                search:"优惠码",
                searchbut:"添加",
                text:"无可使用优惠券",
                coupon1:"推荐有礼",
                coupon2:"专属折扣",
                coupon3:"专属折扣",
                orderNo:"订单号",
                sessions:"课时"
            },
            clockin:{
                title:"打卡",
                type1:"打卡",
                type2:"打卡记录",
                type3:"邀请试听",
                type4:"推荐同学",
                tips:"点击添加相片",
                mintit:"预计获得勋章",
                mintxt:"枚",
                button1:"提交打卡",
                tips2:"下滑查看更多",
                minJi:"小计",
                tips3:"打卡渠道：微信朋友圈、小红书；",
                tips4:"打卡间隔≥3天",
            },
            shop:{
                title1:"会员商城",
                title2:"兑换记录",
                title3:"购物车",
                title4:"订单信息",
                type1:"启蒙",
                type2:"青少年",
                right:"右滑翻页",
                mintit:"预计消耗勋章",
                mintxt:"枚",
                submit1:"购物车",
                submit2:"确认兑换",
                submit3:"提交",
                tips1:"提醒",
                tips2:"下滑查看更多",
                tipstxt1:"受物流费用影响，一次兑换书籍≥6 ",
                tipstxt2:" 才能发货哦亲！",
                sub:"小计",
                sof:"当前书籍小计",
                addresstit:"配送地址",
                addressPay:"账单/配送地址",
                Name:"姓名",
                first_name:"名",
                last_name:"姓",
                Telephone:"电话",
                code:"邮编",
                emil:"输入绑定信用卡的邮箱!",
                Country:"国家",
                prov:"省份/城市",
                prov1:"省/州/地区",
                city:"城市",
                street:"街道地址",
                street1:"街道地址和门牌号",
                buil:"楼栋/房号信息",
                verify:"点我验证"
            },
        },
        'en': {
            shop:{
                title1:"Member Mall",
                title2:"Shopping records",
                title3:"My Cart",
                title4:"Order Details",
                type1:"Enlighten",
                type2:"Juvenile",
                right:"Slide to Flip",
                mintit:"Estimated to use",
                mintxt:"N",
                submit1:"My Cart",
                submit2:"Confirm",
                submit3:"Submit",
                tips1:"Tips",
                tips2:"Slide down to see more",
                tipstxt1:"Considering logistics costs, it is recommended to exchange books ≥6 ",
                tipstxt2:" at a time for shipment! ",
                sub:"Subtotal",
                sof:"Subtotal of books",
                addresstit:"Shipping Address",
                addressPay:"Billing/Delivery Address",
                Name:"Name",
                first_name:"firstName",
                last_name:"lastName",
                Telephone:"Telephone",
                code:"zip code",
                emil:"Enter the email address for binding the credit card.",
                Country:"Country",
                prov:"Province/City",
                prov1:"Province/State/Region",
                city:"City",
                street:"Street Information",
                street1:"Delivery street address and house number",
                buil:"Building/Room Number",
                verify:"Click me to verify"
            },
            user:{
                title:"Personal center",
                title1:"Manage account",
                text1:"Email",
                text2:"Cellphone",
                text3:"WeChat",
                text4:"Country",
                password:"password",
                save:"Save",
                save1:"Edit",
                user1:"Name",
                user2:"Sex",
                user3:"M",
                user4:"F",
                user5:"Birthdate",
                user6:"Grade",
                user7:"Approximate literacy",
                user8:"Approximate volume",
                user9:"Native language",
                user10:"Learned Pinyin or not",
                user11:"Environment at home",
                user12:"Learning experience",
                user13:"Expectations",
                user14:"Detailes",
                user15:"Put away",
            },
            clockin:{
                title:"Sign-in",
                type1:"Sign-in",
                type2:"Records",
                type3:"Audition",
                type4:"Classmate",
                tips:"Click to add photos",
                mintit:"Estimated to get",
                mintxt:"N",
                button1:"Submit",
                tips2:"Slide down to see more",
                minJi:"Total",
                tips3:"Sign-in channels: WeChat Moments, Xiaohongshu;",
                tips4:"Sign-in interval: ≥ 3 days",
            },
            coupon:{
                title:"Coupon",
                type1:"Available",
                type2:"Exchange",
                type3:"Orders",
                search:"Coupon Code",
                searchbut:"ADD",
                text:"No coupon available",
                coupon1:"Invitation Gift",
                coupon2:"Exclusive Discount",
                coupon3:"Discount",
                orderNo:"Order No.",
                sessions:"Classes"
            },
            qrfk:"Confirm",
            cash:{
                title:"Cashier",
                text1:"Counting:  ",
                text2:"Overdue orders will be cancelled",
                stud:"Student",
                order:"Order No",
                zsess:"Total Sessions",
                sfje:"Actual Amount Paid",
                zfje:"Payment Amount",
                zfty:"Payment Method",
                Partial:"Partial payment ",
                Partial2:"Amount to be paid:",
                Partial3:"Click to use",
                submit:"Pay Now",
                pay1:"The First 1/",
                pay2:"The Second 1/",
                pay3:"The Third 1/",
                pay4:"The Fourth 1/",
            },
            bill:{
                title:"Billing Information",
                curr:"Payment Currency",
                currXZ:"Currency",
                stud:"Student",
                cour:"Course Type",
                courXZ:"Course",
                sess:"Purchase Sessions",
                sessXZ:"Sessions",
                comp:"Complimentary Sessions",

                orig:"Total Price",
                unit:"Unit Price",
                coup:"Coupon",
                coupXZ:"No coupon selected",
                actu:"Total Discount Price",
                zsess:"Total Sessions",
                zactu:"Total Price Paid",
                submit:"Submit",
                nouse:"No Use",
            },
            feat:{
                title:"Student Center",
                viptitle:"Member-Centre",
                text1:"Pay",
                text2:"Share",
                text3:"Coupon",
                text31:"My Order",
                text32:"Order Records",
                text4:"Sign-in",
                text5:"Member Mall",
                text6:"Student",
                button:"Confirm",
                total:"Total Class:",
                class:"Class Statistics",
                account:"To add"
            },
            lang:"en",
            home: {
                title: 'Welcome！',
                text1: 'Access to the First-Rate Chinese',
                text2: 'Teachering Resources Around the World!',
                login:"LOG IN",
                log:"Login",
                reg:"Register",
                emil:"Enter email address",
                password:"Please enter the password",
                passwordTwo:"Enter the password again",
                student:"Select student",
                submit:"Confirm",
                tips:"If there is no available account, please contact the administrator for assistance in registering.",
                tipsTwo:"If you forget the password, please contact the administrator for assistance.",
            },
        }
    }
})

export default i18n 