import axios from "axios";
const service = axios.create({
    baseURL: "https://ali-waihui.showapi.com",
    // baseURL: "http://x30v1dfyayv6.hk1.xiaomiqiu123.top",
    // baseURL: "http://117.50.162.250:12318",
    timeout: 10000
})


export default service;
