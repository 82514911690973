import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3cc5cf78"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home_box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ClickLang = _resolveComponent("ClickLang");
  const _component_LoginTitle = _resolveComponent("LoginTitle");
  const _component_LoginInput = _resolveComponent("LoginInput");
  const _component_LoginOrzc = _resolveComponent("LoginOrzc");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ClickLang), _createVNode(_component_LoginTitle), _ctx.type == 0 ? (_openBlock(), _createBlock(_component_LoginInput, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_LoginOrzc, {
    key: 1
  }))]);
}