import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';

import i18n from '@/language/'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './common/css/base.less';
import CountrySelector from 'vue-country-selector';


const app = createApp(App);

app.use(router);
app.use(CountrySelector);
app.use(ElementPlus);
app.use(i18n);
app.use(store)

app.mount('#app');
